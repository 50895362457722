<template>

  <section class="login">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center text-center error-page bg-primary">
          <div class="row flex-grow">
            <div class="col-lg-7 mx-auto text-white">
              <div class="row align-items-center d-flex flex-row">
                <div class="col-lg-6 text-lg-right pr-lg-4">
                  <h1 class="display-1 mb-0"></h1>
                </div>
                <div class="col-lg-6 error-page-divider text-lg-left pl-lg-4">
                  <h2>Authorization... </h2>
                  <h3 class="font-weight-light">The process is going on...</h3>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-12 mt-xl-2">
                  <p class="text-white font-weight-medium text-center"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>

</template>

<script>
 
var cdata = {//Если не указать - не всключиться реактивность
    _id:"", //идентификатор ребенка при запросе в базу димы 
    is_all_loaded: false,
    
    
    
     
    flags:{
                    is_error: {
                        api: false,
                        sending: false,
                    },

                    is_action: {
                        //ok_disabled: false
                    },
                    
                    load: [],
    }
    
}   

     



export default {
  name: 'login',
    data () {  
        return {  
            cdata: cdata,
        };
    },
  
  created() {
         
            this.cdata['cnf'] = this.$root.cnf;
            //this.cdata['_route_']="g1_start"; 
     
  }, 
      
   mounted() {
       
   
        for (const key of Object.keys(this.cdata['flags']['is_error'])) { this.cdata['flags']['is_error'][key]=false; }
        //this.flags['ok_disabled']=false;
        
     
         this.$root._AUTH().do_auth(this.cdata); 
   },
  
}
</script>



<style scoped> 
 
  @media (max-width: 991px){
       .page-body-wrapper {
            padding-top: 0px !important;
        }  
  }
 

</style>